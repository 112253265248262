import { useState } from "react";
import dynamic from "next/dynamic";

import { Dispensary } from "custom-types/Dispensary";

import Image from "components/Image";

const LightBox = dynamic(() => import("components/LightBox"));

const PhotoOverview: React.FC<{
  className?: string;
  name: Dispensary["name"];
  photos?: Dispensary["photos"];
}> = ({ className, name, photos }) => {
  const [showLightbox, setShowLightbox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  if (!photos?.length) {
    return null;
  }

  const toggleLightbox = (index = 0) => {
    setPhotoIndex(index);
    setShowLightbox(!showLightbox);
  };

  return (
    <>
      <div className={className}>
        {showLightbox && (
          <LightBox
            images={photos}
            initialPhotoIndex={photoIndex || 0}
            onToggleLightboxClick={toggleLightbox}
          />
        )}
        <h2 className="mb-sm heading--m font-extrabold">{`Photos of ${name}`}</h2>
        <div className="row mb-md">
          {photos.slice(0, 4).map((photo, i: number) => (
            <button
              className="col-1/2 md:col-1/4 relative mb-md md:mb-none"
              key={photo.imageUrl}
              onClick={() => toggleLightbox(i)}
              aria-label="View enlarged photo in lightbox"
            >
              <Image
                sizes={[250, 360, 175, 240, null, 250]}
                src={photo.imageUrl}
                alt={name}
                ratio="square"
              />
            </button>
          ))}
        </div>
        <button
          onClick={() => toggleLightbox(0)}
          className="font-bold text-green text-sm"
        >
          Show all photos
        </button>
      </div>
    </>
  );
};

export default PhotoOverview;
