import React from "react";
import Link from "next/link";
import pluralize from "pluralize";

import { Action, Category, Label } from "constants/events";
import { Dispensary } from "custom-types/Dispensary";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { dispensaryHref } from "utils/dispensaryUrls";

import StarRating from "components/botanic/StarRating";

import RatingCircle from "./RatingCircle";

const ReviewsHeader: React.FC<{
  dispensary: Dispensary;
  className?: string;
}> = ({ dispensary, className }) => {
  const { publishEvent } = useEventTracker();
  const countryCode = useDomainCountryCode();

  const {
    name,
    slug,
    atmosphereRating,
    qualityRating,
    serviceRating,
    rating,
    id,
    retailType,
    reviewCount,
  } = dispensary;

  const publishWriteReviewEvent = () => {
    publishEvent({
      action: Action.click,
      category: Category.dispensaryMenu,
      dispensaryId: id,
      label: Label.writeReview,
    });
  };

  const writeReviewHref = dispensaryHref({
    additionalPath: "/write-review",
    countryCode,
    retailType,
    slug,
  });

  return (
    <section className={className}>
      <h3 className="heading--m">
        {`${reviewCount || 0} ${pluralize("Review", reviewCount)} of ${name}`}
      </h3>
      {reviewCount > 0 && (
        <>
          <StarRating
            rating={rating || 0}
            starWidth={18}
            className="mb-xl text-sm"
            ratingCount={reviewCount}
          />
          <div className="flex mb-xl">
            <RatingCircle
              rating={qualityRating}
              label="Quality"
              className="mr-xxl"
            />
            <RatingCircle
              rating={serviceRating}
              label="Service"
              className="mr-xxl"
            />
            <RatingCircle rating={atmosphereRating} label="Atmosphere" />
          </div>
          <Link
            prefetch={false}
            href={writeReviewHref}
            className="button button--secondary"
            data-testid="write-review-button"
            onClick={publishWriteReviewEvent}
          >
            write a review
          </Link>
        </>
      )}
    </section>
  );
};

export default ReviewsHeader;
