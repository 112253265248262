import classnames from "classnames";

import { Dispensary } from "custom-types/Dispensary";
import useDispensaryUpdates from "hooks/useDispensaryUpdates";

import Button from "components/botanic/Button";
import UpdateCard from "components/Dispensary/DispensaryUpdate";
import Placeholder, { Rect } from "components/Placeholder";

import UpdatesSectionNoResults from "./UpdatesNoResults";

const PER_PAGE = 3;

const UpdatesSection: React.FC<{
  className?: string;
  dispensary: Dispensary;
}> = ({ className, dispensary }) => {
  const { page, ref, setPage, totalCount, updates } = useDispensaryUpdates(
    dispensary.slug,
  );

  return (
    <div className={classnames(className)} ref={ref}>
      <h2 className="heading--m mb-sm">Updates from {dispensary.name}</h2>
      <div className="row">
        {updates === undefined ? (
          Array(PER_PAGE)
            .fill(null)
            .map((_, i) => (
              <div className="col lg:col-4 mb-lg" key={i}>
                <Placeholder height="192" width="100%">
                  <Rect height="100%" width="100%" />
                </Placeholder>
              </div>
            ))
        ) : updates.length ? (
          updates.map((u, i) => (
            <div
              className="col lg:col-4 mb-lg"
              key={i}
              data-testid="update-card"
            >
              <UpdateCard
                update={u}
                className="h-full"
                timeZone={dispensary.timeZone}
              />
            </div>
          ))
        ) : (
          <div className="col" data-testid="updates-no-results">
            <UpdatesSectionNoResults
              dispensaryName={dispensary.name}
              dispensaryId={dispensary.id}
              className="h-[572px] lg:h-[192px]"
            />
          </div>
        )}
      </div>
      {totalCount > 0 && page * PER_PAGE < totalCount && (
        <Button className="w-full" onClick={() => setPage(page + 1)}>
          show more updates
        </Button>
      )}
    </div>
  );
};

export default UpdatesSection;
