import "@splidejs/react-splide/css";

import React from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";

import { EducationalMaterialsStoreFrontImage } from "redux/reducers/complianceRules";

import Image from "components/Image";

type Props = { images: EducationalMaterialsStoreFrontImage[] };

const StoreFrontImages: React.FC<Props> = ({ images }) => {
  return (
    <div
      id="educational-materials-carousel"
      className="border-y border-light-grey py-8 mb-8"
    >
      <Splide
        aria-label="Educational Materials Carousel"
        hasTrack={false}
        options={{
          autoplay: true,
          interval: 5000,
          type: "loop",
        }}
      >
        <SplideTrack role="tablist">
          {images.map((image, index) => {
            return (
              <SplideSlide key={index}>
                <div className="w-full flex justify-center">
                  <a href={image.linkUrl} target="_blank">
                    <Image
                      alt={image.altText}
                      src={image.sourceUrls.desktop}
                      mobileSrc={image.sourceUrls.mobile}
                      sizes={[320, null, 728, null, null, 728]}
                    />
                  </a>
                </div>
              </SplideSlide>
            );
          })}
        </SplideTrack>
      </Splide>

      <style jsx global>{`
        #educational-materials-carousel :global(.splide__arrows) {
          display: none;
        }
        #educational-materials-carousel :global(.splide__pagination) {
          display: none;
        }
      `}</style>
    </div>
  );
};

export default StoreFrontImages;
