import consumerApi from "api/services/consumer-api";
import { Update } from "custom-types/Update";
import logError from "utils/logError";

type Response = {
  data: Update[];
  metadata: {
    totalCount: number;
  };
};

export const getDispensaryUpdates = async (
  slug: string,
  skip = 0,
  take = 10,
): Promise<Response> => {
  try {
    const { data } = await consumerApi.get(
      `/api/dispensaries/v1/${slug}/updates`,
      { params: { skip, take } },
    );

    return data;
  } catch (e) {
    logError(e.message, {
      functionName: "getDispensaryUpdates",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return {
      data: [],
      metadata: {
        totalCount: 0,
      },
    };
  }
};
