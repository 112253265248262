import Link from "next/link";

import { Action } from "constants/events";
import { useEventTracker } from "hooks/useEventTracker";

export const MSOLink: React.FC<{ slug: string }> = ({ slug }) => {
  const { publishEvent } = useEventTracker();

  return (
    <Link
      data-testid="mso-finder-link"
      href={`/dispensaries/locations/${slug}`}
      className="text-green text-sm underline font-bold"
      onClick={() =>
        publishEvent({
          action: Action.click,
          category: "Dispensary",
          label: "see all locations",
        })
      }
    >
      See all locations
    </Link>
  );
};
