const SponsoredVideo: React.FC<{ sponsoredVideoId?: string }> = ({
  sponsoredVideoId,
}) => {
  if (!sponsoredVideoId) {
    return null;
  }

  return (
    <div className="mb-section">
      <div className="section__header">
        <h3 className="heading--m">Video</h3>
      </div>
      <div className="relative pb-[56.25%]">
        <iframe
          title="dispensary video"
          className="absolute top-0 left-0 w-full h-full"
          src={`https://www.youtube.com/embed/${sponsoredVideoId}`}
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default SponsoredVideo;
