import React from "react";
import classnames from "classnames";

type Props = {
  rating: number;
  className?: string;
  label?: string;
};

const RatingCircle: React.FC<Props> = ({ rating, label, className }) => {
  if (!rating && rating !== 0) {
    return null;
  }

  return (
    <>
      <div
        className={classnames(
          "font-bold flex flex-col items-center w-[45px]",
          className,
        )}
      >
        <div
          data-testid="rating-circle"
          className="bg-deep-green-alt m-auto w-[45px] h-[45px] leading-[45px] rounded-full text-center text-sm"
        >
          {rating.toFixed(1)}
        </div>
        <div className="text-center text-xs">{label}</div>
      </div>
    </>
  );
};

export default RatingCircle;
