import React from "react";
import Link from "next/link";

import { Dispensary } from "custom-types/Dispensary";
import { DispensaryReview } from "custom-types/Reviews";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { dispensaryHref } from "utils/dispensaryUrls";
import { trackMenuEvent } from "utils/trackEvent";

import NoReviews from "components/Dispensary/Reviews/components/NoReviews";
import ReviewContainer from "components/Dispensary/Reviews/components/ReviewContainer";
import ReviewsHeader from "components/Dispensary/ReviewsHeader";

const ReviewsOverview: React.FC<{
  dispensary: Dispensary;
  reviews?: DispensaryReview[];
}> = ({ dispensary, reviews }) => {
  const { slug, retailType } = dispensary;
  const countryCode = useDomainCountryCode();

  const onClick = () => {
    trackMenuEvent("Click", "See all Reviews Button");
  };

  const href = dispensaryHref({
    additionalPath: "/reviews",
    countryCode,
    retailType,
    slug,
  });

  return (
    <div id="reviews-overview">
      <ReviewsHeader dispensary={dispensary} />
      {reviews && reviews.length > 0 ? (
        <>
          <div className="mt-lg">
            {reviews.map((review) => (
              <ReviewContainer
                retailType={retailType}
                review={review}
                key={review.id}
                hideMetadata={true}
              />
            ))}
          </div>
          <Link
            prefetch={false}
            href={href}
            data-testid="reviews-overview-see-all"
            onClick={onClick}
            className="button button--secondary"
          >
            see all reviews
          </Link>
        </>
      ) : (
        <NoReviews dispensary={dispensary} />
      )}
    </div>
  );
};

export default ReviewsOverview;
