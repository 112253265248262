import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

import { getDispensaryUpdates } from "api/requests/getDispensaryUpdates";
import { Update } from "custom-types/Update";

const useDispensaryUpdates = (slug: string, perPage = 3) => {
  const [page, setPage] = useState(1);
  const [updates, setUpdates] = useState<Update[]>();

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const totalCount = useRef<number>();
  useEffect(() => {
    if (inView) {
      const getUpdates = async () => {
        const { data, metadata } = await getDispensaryUpdates(
          slug,
          perPage * (page - 1),
          perPage,
        );

        if (totalCount.current === undefined) {
          totalCount.current = metadata.totalCount;
        }
        setUpdates(updates ? [...updates, ...data] : data);
      };

      getUpdates();
    }
  }, [inView, page, slug]);

  return {
    page,
    ref,
    setPage,
    totalCount: totalCount.current || 0,
    updates,
  };
};

export default useDispensaryUpdates;
