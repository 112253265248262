import React from "react";
import Link from "next/link";

import { Action, Category, Label } from "constants/events";
import { Dispensary } from "custom-types/Dispensary";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { dispensaryHref } from "utils/dispensaryUrls";

import StoreIllustrationIcon from "components/Icons/store_illustration.svg";

const NoReviews: React.FC<{
  dispensary: Dispensary;
}> = ({ dispensary }) => {
  const { slug, id, retailType } = dispensary;

  const { publishEvent } = useEventTracker();
  const countryCode = useDomainCountryCode();

  const publishWriteReviewEvent = () => {
    publishEvent({
      action: Action.click,
      category: Category.dispensaryMenu,
      dispensaryId: id,
      label: Label.writeReview,
    });
  };

  const writeReviewHref = dispensaryHref({
    additionalPath: "/write-review",
    countryCode,
    retailType,
    slug,
  });

  return (
    <section
      className="container flex items-center flex-col mb-section mt-xl py-xxl px-md border border-light-grey rounded"
      data-testid="write-review"
    >
      <StoreIllustrationIcon />
      <div className="text-md font-bold mb-lg">No reviews yet</div>
      <div className="mb-lg text-center">
        Tried this location? Help out the community by sharing your experience.
      </div>
      <div>
        <Link
          prefetch={false}
          href={writeReviewHref}
          className="button button--secondary"
          data-testid="write-review-button"
          onClick={publishWriteReviewEvent}
        >
          write a review
        </Link>
      </div>
    </section>
  );
};

export default NoReviews;
