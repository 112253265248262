import React from "react";

import { Action, Category } from "constants/events";
import { Dispensary } from "custom-types/Dispensary";
import { useEventTracker } from "hooks/useEventTracker";
import buildDispensaryGoogleMapsUrl from "utils/dispensary/buildDispensaryGoogleMapsUrl";
import getDispensaryPrimaryAddress from "utils/dispensary/getDispensaryPrimaryAddress";
import isDispensaryDeliveryOnly from "utils/dispensary/isDispensaryDeliveryOnly";
import isDispensaryUnclaimed from "utils/dispensary/isDispensaryUnclaimed";

import LocationMarkerIcon from "components/Icons/location_marker.svg";

const PrimaryAddress: React.FC<{ dispensary: Dispensary }> = ({
  dispensary,
}) => {
  const { publishEvent } = useEventTracker();

  const primaryAddress = getDispensaryPrimaryAddress(dispensary);
  const isDeliveryOnly = isDispensaryDeliveryOnly(dispensary.tags);

  if (!primaryAddress || isDeliveryOnly) {
    return null;
  }

  const isUnclaimed = isDispensaryUnclaimed(dispensary);
  const googleMapsUrl = buildDispensaryGoogleMapsUrl(dispensary);

  return (
    <>
      <a
        data-testid={"primary_address__get_directions"}
        onClick={() =>
          publishEvent({
            action: Action.click,
            category: Category.dispensaryFooter,
            dispensaryId: dispensary.id,
            label: "get directions",
          })
        }
        target="_blank"
        rel="noopener noreferrer"
        href={googleMapsUrl || ""}
      >
        <div className="text-sm">
          <div className="flex items-center">
            {!isUnclaimed && (
              <LocationMarkerIcon width="24" height="24" className="mr-sm" />
            )}
            <span className="underline" data-testid="primary-location">
              {primaryAddress}
            </span>
          </div>
        </div>
      </a>
    </>
  );
};

export default PrimaryAddress;
