import classNames from "classnames";

import { Dispensary } from "custom-types/Dispensary";

import FollowDispensaryButton from "components/Dispensary/FollowDispensaryButton";
import NotFoundIcon from "components/Icons/not_found.svg";

const UpdatesSectionNoResults: React.FC<{
  className?: string;
  dispensaryId: Dispensary["id"];
  dispensaryName: string;
}> = ({ className, dispensaryId, dispensaryName }) => (
  <div
    className={classNames(
      "border border-light-grey rounded flex justify-center items-center p-lg",
      className,
    )}
  >
    <div className="text-center">
      <NotFoundIcon className="mx-auto mb-sm" width={48} />
      <p className="font-bold text-center">
        There are no {dispensaryName} updates right now
      </p>
      <p className="text-xs">Check back later or favorite for updates</p>
      <FollowDispensaryButton
        buttonType="basic"
        className="block mx-auto !w-full"
        dispensaryName={dispensaryName}
        dispensaryId={dispensaryId}
      />
    </div>
  </div>
);

export default UpdatesSectionNoResults;
