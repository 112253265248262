import React from "react";
import classnames from "classnames";
import get from "lodash/get";

import { Dispensary } from "custom-types/Dispensary";
import { Update } from "custom-types/Update";

import ExpandableContainer from "components/botanic/ExpandableContainer";

const UpdateCard: React.FC<{
  update: Update;
  className?: string;
  timeZone: Dispensary["timeZone"];
}> = ({ update, className, timeZone }) => {
  const hideExpander = get(update, "updateText.length", 0) < 150;

  const formattedDate = new Date(update.lastModified).toLocaleDateString(
    "en-US",
    {
      day: "numeric",
      month: "long",
      timeZone: timeZone,
      year: "numeric",
    },
  );

  return (
    <div className={classnames("bg-leafly-white pt-lg px-lg", className)}>
      <div>{update.title}</div>
      <div className="text-xs mb-md">{formattedDate}</div>
      <ExpandableContainer
        hide={hideExpander}
        theme="leafly-white"
        initialHeight="72px"
      >
        <p className="text-sm">{update.updateText}</p>
      </ExpandableContainer>
    </div>
  );
};

export default UpdateCard;
