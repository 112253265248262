import React from "react";
import classNames from "classnames";

import { Themes } from "custom-types/Themes";

type Props = {
  as?: React.ElementType;
  children?: React.ReactNode;
  className?: string;
  theme?: keyof typeof Themes;
};

const Tag: React.FC<Props> = ({
  as,
  children,
  className,
  theme,
  ...others
}) => {
  const Element = as || "span";
  const isPreferredPickupPartner = theme === Themes.preferredPickupPartner;
  const isPickup = isPreferredPickupPartner || theme === Themes.pickup;

  const classes = classNames(
    "tag border border-green-60 font-bold px-md rounded-full text-xs inline-flex items-center",
    {
      "border-pickup text-pickup": isPreferredPickupPartner,
    },
    className,
  );

  return (
    <Element className={classes} {...others}>
      {isPickup && !children ? "Pickup" : children}
      {isPreferredPickupPartner && <span className="ml-1">✶</span>}
    </Element>
  );
};

export default Tag;
